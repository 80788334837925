<template>
	<div class="login-container">
		<el-form :model="ruleForm2" :rules="rules" status-icon ref="ruleForm2" label-position="left" label-width="0px"
			class="demo-ruleForm login-page">
			<h3 class="title">系统登录</h3>
			<el-form-item prop="account" >
				<el-input type="text" v-model="ruleForm2.account" auto-complete="off" placeholder="请输入账号">
				</el-input>
			</el-form-item>
			<el-form-item prop="salted" >
				<el-input type="password" v-model="ruleForm2.salted" auto-complete="off" placeholder="请输入密码"></el-input>
			</el-form-item>
			<!-- <el-form-item prop="mobile">
				<el-input type="text" v-model="ruleForm2.mobile" auto-complete="off" placeholder="请输入手机号码"></el-input>
			</el-form-item>
			<el-form-item prop="verificationCode" >
				<el-input class="inputCode" style="width:70%" v-model="ruleForm2.verificationCode" placeholder="请输入验证码"></el-input>
				<el-button @click="getCode" type="success" :disabled="!ruleForm2.show" >获取验证码
				<span v-show="!ruleForm2.show" style="font-size:5px" class="count">({{countDown}} s)</span></el-button>
          </el-form-item> -->
			<!-- <div class="tk"><el-checkbox v-model="checked"><span class="he">我已阅读并同意</span></el-checkbox><span class="detail" @click="dialogVisible = true">平台用户隐私政策协议</span><span class="he">和</span>
			<span class="detail" @click="dialogVisibles = true">平台注册协议</span></div> -->
			<el-form-item>
				<el-button type="primary" style="width: 100%" @click="submitForm('ruleForm2')">登录</el-button>
			</el-form-item>
		</el-form>
		<div>
			<!-- <a href="https://beian.miit.gov.cn/"  class="zs" target="_blank">蜀ICP备2022027672号-1</a> -->
		</div>
		<el-dialog
			title="平台用户隐私政策协议"
			:visible.sync="dialogVisible"
			:modal-append-to-body="false">
			<ptDetail></ptDetail>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog
			title="平台注册协议"
			:visible.sync="dialogVisibles"
			:modal-append-to-body="false">
			<zcDetails></zcDetails>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisibles = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import ptDetail from "./ptDetail"
	import zcDetails from "./zcDetails"
	


	export default {
		name: "login",
		data() {
			return {
				countDown:'',
				checked:false,
				checkeds:false,
				dialogVisible: false,
				dialogVisibles: false,
				url: "",
				ruleForm2: {
					account: "",
					salted: "",
					mobile:'',
					verificationCode:'',
					show: true,
					
				},
				rules: {
					account: [{
						required: true,
						message: "请输入账号",
						trigger: "blur",
					}, ],
					salted: [{
						required: true,
						message: "请输入密码",
						trigger: "change",
					}, ],
					// mobile: [{
					// 	required: true,
					// 	message: "请输入手机号码",
					// 	trigger: "change",
					// }, ],
					// verificationCode: [{
					// 	required: true,
					// 	message: "请输入手机验证码",
					// 	trigger: "change",
					// }, ],
				},
			};
		},
		created() {

			this.clearCookie("sessionId");
			this.clearCookie("name");

			var that = this;
			document.onkeydown = function(e) {
				var key = window.event.keyCode;
				if (key === 13) {
					that.submitForm('ruleForm2'); // 触发事件
				}
			};
		},
		methods: {
			      //发送验证码
      async getCode(){
        const TIME_COUNT = 60;
				if (!this.timer) {
					this.countDown = TIME_COUNT;
					this.show = false;
					this.timer = setInterval(() => {
						if (this.countDown > 0 && this.countDown <= TIME_COUNT) {
							this.countDown--;
						} else {
							this.show = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000)
				}
        await this.api.salaryApi.sendMobile(this.ruleForm2).then((res) => {
          console.log("-------发送验证码",res);
          res = res.data
          if(res.code == 200){
            this.$message.success(res.msg);
          }else{
            this.$message.error(res.msg);
          }
        })
      },
			
			async submitForm(formName) {
				await this.$refs[formName].validate((valid) => {
					if (valid) {
						this.userloginApi();
					} else {
						return false;
					}
				});
			},
			async userloginApi() {
				// if(!this.checked){
				// 	this.$message.warning("请勾选服务条款");
				// 	return;
				// }
				let response = await this.api.platformApi.userlogin(this.ruleForm2);
				// let code = await this.api.salaryApi.checkCode(this.ruleForm2);
				// console.log(code);
				console.log(response);
				
				var s = JSON.stringify(response.data.data);
				if (response.data.data[0] == "false") {
					this.$message.error("登录失败请检查帐号密码!");
				} else if (response.data.data[0] == "disable") {
					this.$message.error("对不起，该用户已被停用!");
				// }else if  (code.data.code != 200){
				// 	this.$message.error("验证码不正确!");
				} else {
					console.log("---------------1",response.data)
					window.localStorage.setItem("platform", response.data.data[2]);
					window.localStorage.setItem("userName", response.data.data[1]);
					window.localStorage.setItem("account", response.data.data[3]);


					this.setCookie("sessionId", response.data.data[0], 1);
					this.setCookie("name", response.data.data[1], 1);
					this.$message({
						message: "登录成功",
						type: "success",
					});
					this.api.platformApi.findCurrentUserTenantInfo(this.ruleForm2).then(resp=>{
						console.log("ceshi----",resp)
						 if(resp.data.code != 200){
							this.$message.error("用户所属租户信息加载失败");
							return;
						}
						let userTenantSize = resp.data.length;
						if(userTenantSize == 0){
							this.$message.error("当前登录用户没有所属租户信息，请联系管理员！");
						}else if(resp.data.length==1){
							console.log(this.$refs);
							this.api.platformApi.checkedTenant({tenantId:resp.data[0].id}).then(resp=>{
								if(resp.code != 200){
									this.$message.error("登陆租户信息失败!");
									return;
								}
								this.$router.replace({
									path: "/menutree",
								});
							})
						}else{
							this.$router.push({
								path: "/checkedTenant/"+this.ruleForm2.account,
								
							});
						}
					})

				}
			},
			// detail(){
			// 	this.$router.push({path: '/ptdetail',query: {}})
			// },
			// details(){
			// 	this.$router.push({path: '/zcdetails',query: {}})
			// },
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			setCookie: function(cname, cvalue, exdays) {
				let d = new Date();
				d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
				let expires = "expires=" + d.toUTCString();
				document.cookie = cname + "=" + cvalue + "; " + expires;
			},
			getCookie: function(cname) {
				let name = cname + "=";
				let ca = document.cookie.split(";");
				// console.log("获取cookie,现在循环")
				for (let i = 0; i < ca.length; i++) {
					let c = ca[i];
					// console.log(c)
					while (c.charAt(0) === " ") c = c.substring(1);
					if (c.indexOf(name) !== -1) {
						return c.substring(name.length, c.length);
					}
				}
				return "";
			},
			clearCookie: function(cname) {
				this.setCookie(cname, "", -1);
			},
		},
		components: {
			ptDetail,
			zcDetails,
		},
	};
</script>

<style scoped>
	.he{
		color: black;
	}
	.tk{
		text-align: left;
		font-size: 13px;
		color: #409EFF;
		padding: 10px 0px;
	}
	.login-container {
		
		/* background: url("../../../static/system/back.jpg"); */
		width: 100%;
		height: 100%;
		position:fixed;
		background-size:100% 100%;
	}

	.login-page {
		-webkit-border-radius: 5px;
		border-radius: 5px;
		margin: 180px auto;
		width: 400px;
		padding: 35px 35px 15px;
		background: #fff;
		border: 1px solid #eaeaea;
		/* margin-left: 20%; */
		box-shadow: 0 0 25px #cac6c6;
	}

	.zs{
		position:absolute; 
		left:45%;
		top:95%;
		color:black;
		text-decoration: none;
		text-align: center;
	}

</style>
