<template>
    <div class="home" id="quotaReviewList">
      <div style="text-align: left" >
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
         <el-form-item label="用工单位:">
          <el-select v-model="queryForm.tenantID" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in this.tenants"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="汇总编号：">
          <el-input v-model="queryForm.summaryNo" placeholder="请输入查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="上传文件名：">
          <el-input v-model="queryForm.fileName" placeholder="请输入查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="发薪状态：">
          <el-select v-model="queryForm.status" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
         
        <el-form-item label="上传时间:">
        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                v-model="queryForm.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00','23:59:59']">
          </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="loadTable" icon="el-icon-search">查询</el-button>
            <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: left;margin-bottom: 20px;">
        <el-button type="primary" icon="el-icon-plus" @click="openUploadFrom()">上传发薪模版</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="downloadSalary">下载发薪模板</el-button>
        <el-button type="primary" icon="el-icon-download" @click="exportTable">导出</el-button>
      </div>
      <div>
          <el-table  :header-cell-style="tableHeader" :cell-style="tableCell" :data="tableData" fit border style="width: 100%">
          <el-table-column type="index" width="50" label="" />
          <el-table-column prop="summaryNo" :show-overflow-tooltip="true"  label="汇总表编号" align="center"/>
          <el-table-column prop="createTime" :show-overflow-tooltip="true" label="上传时间" align="center"/>
          <el-table-column prop="fileName" :show-overflow-tooltip="true" label="上传文件名" align="center"/>
          <el-table-column prop="tenantId" :show-overflow-tooltip="true" label="用工单位" align="center"/>
          <el-table-column prop="totalNetSalary" :show-overflow-tooltip="true" label="总金额" align="center"><template slot-scope="scope">{{accDiv(scope.row.totalNetSalary,100)}}</template></el-table-column>
          <el-table-column prop="status" label="发薪状态" :show-overflow-tooltip="true" align="center" width="150px">
            <template slot-scope="scope">
              <div v-for="(item, index) in itemLists" v-bind:key="index" >
                <div v-if="item.datavalue == scope.row.status">
                  {{  item.name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="recordNumber" label="总条数" :show-overflow-tooltip="true" align="center"/>
          <el-table-column prop="errorCount" :show-overflow-tooltip="true" label="失败条数" align="center">
            <template slot-scope="scope">
              <el-link :type="scope.row.errorCount == 0 ? 'info' : 'danger'" @click="openError(scope.row)" :disabled="scope.row.errorCount == 0" size="medium" style="font-weight: bold;">{{scope.row.errorCount}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="errdsp" label="失败描述" :show-overflow-tooltip="true" align="center" width="250px"/>
          
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button @click="noDetails(scope.row)" type="text" size="small">详情</el-button> 
              <el-button type="text" size="small" @click="submitAudit(scope.row)" v-if="scope.row.status != 6">删除</el-button>
              <!-- <el-button type="text" size="small" @click="submitAudits(scope.row)" v-if="scope.row.status != 6" style="color: #09BF88">发薪</el-button> -->
            </template>
          </el-table-column>
          <el-table-column prop="submitUser" :show-overflow-tooltip="true" label="提交人员" align="center"/>
        </el-table>
       <el-pagination
          id="page"
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          @prev-click="currentChange"
          @next-click="currentChange"
          :page-sizes="[5, 10, 20]"
          :page-size="queryForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <div>

        <!-- 外层 -->
        <el-dialog :title="title" :visible.sync="dialogFormVisible" width="50%" style="text-align: initial;" @closed="afteraddOrUpdateDialogClose">
          <!-- 内层 -->
          <el-form :model="updateForm" :label-position="'left'" ref="updateForm" :rules="updateFormRules" label-width="150" class="updateForm" >
            <el-form-item label="公司名称:">
              <el-select v-model="queryForm.tenantID" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in this.tenants"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="title"  prop="isFile" >
              <el-upload
                class="upload-demo"
                ref="upload"
                :before-upload="handleBeforeUpload"
                :action="upload"
                :on-preview="handlePreview"
                :on-error="handleError"
                :on-remove="handleRemove"
                :on-success="handlesuccess"
                :file-list="fileList"
                :limit=1
                accept=".xls,.xlsx"
                :data="uploadJson"
                :auto-upload="false"
                name="file">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->
                <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件。</div>
              </el-upload>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitUploadFrom">确 定</el-button>
          </div>
        </el-dialog>

        <el-dialog
        :visible.sync="auditMsg.dialogVisible"
        width="30%"
        >
        <span :class="auditMsg.msgClass">{{auditMsg.msg}}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="auditMsg.dialogVisible = false">取 消</el-button>
          <el-button :class="auditMsg.buttonClass" type="primary" @click="deleteSalary">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        :visible.sync="auditMsgs.dialogVisible"
        width="30%"
        >
        <span :class="auditMsgs.msgClass">{{auditMsgs.msg}}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="auditMsgs.dialogVisible = false">取 消</el-button>
          <el-button :class="auditMsgs.buttonClass" type="primary" @click="pay">确 定</el-button>
        </span>
      </el-dialog>
  
      </div>
    </div>
  </template>
  <script>
  
  import XLSX from "xlsx"
  
  import validateUtils from '@/util/validateUtils'
  import { re } from 'mathjs'
  import { Progress } from "element-ui"
  import { title } from "process"
  
  export default {
      data() {
        return {
          options: [{
          value: '2',
          label: '待发薪'
        }, {
          value: '7',
          label: '银行处理中'
        }, {
          value: '6',
          label: '发薪完成'
        }, {
          value: '5',
          label: '发薪完成(部分失败)'
        }, {
          value: '8',
          label: '发薪失败'
        }],
        
          upload:process.env.VUE_APP_BASE_URL+'/salarySummarizing/importSalary',
          title:'上传发薪模版',
          queryForm: {
            status: '',
            tenantID:'',
            summaryNo: '',
            time:[],
            fileName:'',
            pageNo: 1,
            pageSize: 10
          },
          total:0,
          // 这个站点对象集合
          fullscreenLoading: false,
          // 表格对象
          updateFormRules: {
            isFile:[
                { required: true, message: '请选择文件！', trigger: 'change' }
            ]
         },
         tenants:'',
         itemLists:[],
         dictions: {
            code: "summaryStatus",
        },
          tableData: [],
          uploadJson:{
            json: {},
            userId: '',
            flag:1,
            sessionId:'',
            tenantId:'',
            fileName:'',
            type: process.env.VUE_APP_UPLOAD_TYPE,
            queue: process.env.VUE_APP_UPLOAD_SALARY_SUMMARY_QUENCE_NAME
          },
          dialogFormVisible: false,
          fileList: [],
        updateForm: {
            groupIds: [],
            groupId: this.queryFormGetGroupIds,
            groupName: '',
            sessionId: '',
            type: 0,
            creditLimitApprovalId: '',
            isFile: null,
        },
        changeStateParams:{
        id: ''
      },
      auditMsgs: {
          dialogVisible: false,
          msg: '确认撤销审核该汇总表吗？',
          msgClass: 'submitMsg',
          buttonClass: 'submitButton',
        },
        auditMsg: {
          dialogVisible: false,
          msg: '确认撤销审核该汇总表吗？',
          msgClass: 'submitMsg',
          buttonClass: 'submitButton',
          // 提交为0，撤销为1
          status: 0
        },
        }
      },
      computed: {
      },
      watch: {
      },
      // 实例创建完成后调用
  
      mounted: function(){
        this.findDictionariesItemLists(this.dictions);
          },
      async created(){
        this.loadTable();
      },
      methods: {
        openError(row){
        let summid = row.id;
        let summaryNo = row.summaryNo;
        let createTime = row.createTime;
        this.$router.push({path:'/authFailedList',query: {summid,summaryNo,createTime}})
      },
    //查询客户信息
    async listTenantInfos() {
      this.tableLoading = true;
      await this.api.salaryApi.finTenants(this.queryForm).then(res => {
        console.log("-----",res)
          this.tenants = res.data;
        });
    },
  
         // 分页查看薪资信息列表
          async loadTable(){
              this.loading = true;
              let flag = this;
              //getStaffList
              console.log(this.queryForm);
              await this.api.salaryApi.easyPayList(this.queryForm).then((res) => {
                  res = res.data
                  console.log("----计薪列表-----",res);
                  // flag.loading = false;
                  if(res.code == 200){
                      flag.tableData = res.data.records;
                      flag.total = res.data.total
                  }else{
              this.tableData =  null;
              this.total = 0;
              this.$message.error(res.msg);
            }
              })
              this.listTenantInfos();
          },

          async deleteSalary(){
            console.log("-------canshu",this.changeStateParams.id)
            await this.api.salaryApi.deleteSalary(this.changeStateParams).then((res) => {
              res = res.data
              console.log("----删除-----",res);
              if(res.code == 200){
                this.$message({
                  message: '删除成功！',
                  type: 'success'
                });
                 this.auditMsg.dialogVisible = false;
                 this.loadTable();
              }else{
                  this.$message.error(res.msg);
                }
            })
          },

        handleBeforeUpload(file) {
          // 获取文件名
          this.uploadJson.fileName = file.name;
          return true;
        },

        async submitAudit(row){
          this.auditMsg.dialogVisible = true;
          this.auditMsg.msg='确认删除吗？'
          this.auditMsg.msgClass='submitMsg'
          this.auditMsg.buttonClass='submitButton'
          this.changeStateParams.id=row.id;
        },

        async submitAudits(row){
          this.auditMsgs.dialogVisible = true;
          this.auditMsgs.msg='确认发薪吗？'
          this.auditMsgs.msgClass='submitMsg'
          this.auditMsgs.buttonClass='submitButton'
          this.changeStateParams.id=row.id;
        },

      downloadSalary(){
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = process.env.VUE_APP_BASE_URL+process.env.VUE_APP_PAYROLL_TEMPLATES;
        link.setAttribute('download', decodeURIComponent(process.env.VUE_APP_PAYROLL_TEMPLATES))
        document.body.appendChild(link)
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
        console.log('下载成功！')
      },
      toFloat(val){
              if(this.isEntity(val)){
                  return '';
              }
              return parseFloat(val)/10000+"%";
          },
        
        // 查看函数
        noDetails(row){
          this.$router.push({path: '/summaryDetails',query: {id: row.id,summaryNo:row.summaryNo,updateTime:row.updateTime,status:row.status,total_money:row.totalNetSalary, file: row.fileName}})
        },
        // 点击上传汇总表
        openUploadFrom(){
          this.dialogFormVisible = true;
        },
        // parseXlsxPageChange
        parseXlsxPageChange(page){
          console.log(page+'page');
          this.parsePage.pageNo = page;
        },
        //parseXlsxPagesizeChange 
        parseXlsxPagesizeChange(size){
          console.log(size+'size');
          this.parsePage.pageSize = size;
        },
        // 分页器 size改变是触发
         //current-change
        currentChange(page){
          this.queryForm.pageNo = page;
          this.loadTable();
        },
  
        // size-change
        sizeChange(size){
          this.queryForm.pageSize = size;
          this.loadTable();
        },
        // 上传表单
        submitUploadFrom(){
          console.log('开始上传');
          this.uploadJson.sessionId = this.$getCookie("sessionId");
          this.uploadJson.tenantId = this.queryForm.tenantID;
          this.$refs.upload.submit();
          console.log('上传成功！');
        },
        // afteraddOrUpdateDialogClose 清空弹窗表单
        afteraddOrUpdateDialogClose(){
            this.resetForm('updateForm');
            this.$refs.upload.$data.uploadFiles = [];
        },
        resetForm(formName) {
          this.$refs[formName].resetFields();
        },
        //发薪
        pay(id){
          console.log("----------0000",this.changeStateParams)
          this.api.salaryApi.pay(this.changeStateParams).then((res)=>{
            res = res.data
            if(res.code == 200){
              this.$message.success(res.msg)
              this.auditMsgs.dialogVisible = false;
              this.loadTable()
            }else{
              this.$message.error(res.msg)
              this.auditMsgs.dialogVisible = false;
            }
          })
        },
        async findDictionariesItemLists(param) {
          let response = await this.api.platformApi.findDictionariesItemList(param);
          console.log("数据字典", response);
          this.itemLists = response.data.data;
        },

      async exportTable(){
        await this.api.salaryApi.downloadSalaryList(this.queryForm).then(res =>{
          console.log(res)
          let fileNameEncode = "发薪记录.xlsx";
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel"});
          console.log(blob);
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download",  decodeURIComponent(fileNameEncode));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      },
        // 重置
        onReset(){
          this.queryForm.summaryNo='';
          this.queryForm.status='',
          this.queryForm.tenantID='',
          this.queryForm.time=[],
          this.queryForm.fileName=''
          this.loadTable();
        },
        // 上传表格
        submitUpload() {
          console.log('submitUpload!');
          this.$refs.upload.submit();
        },
        // 删除一选择的上传文件函数
        handleRemove(file, fileList) {
          console.log('handleRemove!');
          this.updateForm.isFile = null;
          console.log(file, fileList);
        },
        // 点击文件列表中已上传的文件时的钩子
        handlePreview(file) {
          console.log('handlePreview!');
          console.log(file);
        },
        // 上传失败的钩子
        handleError(err, file, fileList){
          this.$message.error('上传失败！');
        },
        // 上传成功的钩子
        handlesuccess(response, file, fileList){
          this.updateForm.groupId = [];
          this.fileList = []
          this.dialogFormVisible = false;
          this.fullscreenLoading = true;
          if(response.code == 200){
            setTimeout(() => {
              this.fullscreenLoading = false;
              this.$message({
              message: '上传成功！',
              type: 'success'
            });
              this.loadTable();
            }, 2000);
          }else{
            setTimeout(() => {
              this.fullscreenLoading = false;
              const cl = JSON.parse(response.msg)
              let cl2 = ''
              cl.map(item =>{
                cl2 += `<div>${item}</div>`
                return item;
              })
              this.$alert(cl2, '错误提示', {
                confirmButtonText: '确定',
                dangerouslyUseHTMLString: true
              });
              this.loadTable();
            }, 2000);
          }
          },
        tableHeader(column) {
          return "height:36px;background-color:#fafafa;color:#333333;padding:0";
        },
        tableCell(column) {
          return "height:40px;background-color:#fafafa;color:#000000;padding:0";
        },
      },
  }
  </script>
  
  <style scoped>
    .submitMsg{
      color: red;
      font-size: 1.5rem;
      
    }
    .submitButton{
      background-color: red;
      border: none;
    }
    .submitButton:hover{
      background-color: red;
    }
    .repealMsg{
      color: #FFAC1D;
      font-size: 1.5rem;
    }
    .repealButton{
      background-color: #FFAC1D;
      border: none;
    }
    .repealButton:hover{
      background-color: #FFBD4A;
    }
  
    .cancellationMsg{
      color: #F78969;
      font-size: 1.5rem;
    }
    .cancellationButton{
      background-color: #F78969;
      border: none;
    }
    .cancellationButton:hover{
      background-color: #F789;
    }
  /* -------------------------------------- */
  
  #page {
    float: right;
    margin-top: 15px;
  }
  
  #quotaReviewList {
    overflow: hidden;
    background-color: white;
    padding: 15px 20px 100px;
  }
  
  .el-dialog__body {
    border-top: 1px solid rgba(228, 228, 228, 1);
    border-bottom: 1px solid rgb(228, 228, 228, 1);
    padding-left: 0;
    padding-right: 0;
  }
  
  .el-drawer.rtl {
    overflow: scroll;
  }
  
  .updateForm{
    width: 90%;
    margin: 0 auto;
  }
  .parseXlsxBottom{
    text-align: end;
    padding: 20px;
  }
  </style>
  
  
  