import '@/plugins/axios';
import Axios from 'axios';



/**
 * 薪资-审批列表
 */
 export const salaryApproval = params => axios.post("/salarySummarizing/salaryApproval", params).then(data => data)

/**
 * 薪资-提交审核
 */
export const updateSubmitReviewStatus = params => axios.post("/salarySummarizing/updateSubmitReviewStatus", params).then(data => data)

/**
 * 薪资-审核完成
 */
 export const updateAuditCompletedStatus = params => axios.post("/salarySummarizing/updateAuditCompletedStatus", params).then(data => data)

 /**
 * 薪资-撤销审核
 */
export const updateRevokeApprovalStatus = params => axios.post("/salarySummarizing/updateRevokeApprovalStatus", params).then(data => data)

/**
 * 薪资-审核失败
 */
 export const updateAuditFailedStatus = params => axios.post("/salarySummarizing/updateAuditFailedStatus", params).then(data => data)

 /**
 * 薪资-作废
 */
export const updateNullifyStatus = params => axios.post("/salarySummarizing/updateNullifyStatus", params).then(data => data)

/**
 * 薪资-根据薪资表id查询汇总发薪成功数据
 */
export const summarizingSuccessDetail = params => axios.post("/salarySummarizing/summarizingSuccessDetail",params).then(data => data)

/**
 * 薪资-根据薪资表id查询汇总表详情
 */
 export const summarizingDetail = params => axios.post("/salarySummarizing/summarizingDetail",params).then(data => data)


/**
 * 薪资-根据薪资表id查询汇总发薪失败数据
 */
 export const summarizingFailDetail = params => axios.post("/salarySummarizing/summarizingFailDetail",params).then(data => data)

/**
 * 薪资-调整薪资
 */
export const updateSalaryById = params => axios.post("/salarySummarizing/updateSalaryById",params).then(data => data)

/**
 * 薪资-查询状态为1的审批信息
 */
export const summarizingStatusOneAndTwo = params => axios.post("/salarySummarizing/summarizingStatusOneAndTwo",params).then(data => data)

/**
 * 薪资-查询状态为1的审批信息
 */
 export const salaryapply = params => axios.post("/salaryapply/salaryPayment",params).then(data => data)

 /**
 * 薪资-根据用户查询调薪记录
 */
export const salaryHistory = params => axios.post("/salarySummarizing/salaryHistory",params).then(data => data)

/**
 * 薪资管理-发薪记录查询
 */
export const payrollLog = params => axios.post("/salarySummarizing/payrollLog",params).then(data => data)

/**
 * 薪资管理-发薪记录详情
 */
 export const payrollLogDetail = params => axios.post("/salarySummarizing/payrollLogDetail",params).then(data => data)

/**
 * 
 */
export const downloadSalary = params => axios({method:'post',url:"/salarySummarizing/downloadSalary", data:params,responseType:"blob"}).then(data => data)

/**
 * 
 */
 export const downloadSalarySuccess = params => axios({method:'post',url:"/salarySummarizing/downloadSalarySuccess", data:params,responseType:"blob"}).then(data => data)

 /**
 * 
 */
export const downloadSalaryFail = params => axios({method:'post',url:"/salarySummarizing/downloadSalaryFail", data:params,responseType:"blob"}).then(data => data)


/**
 * 
 */
 export const downloadTotalSalary = params => axios({method:'post',url:"/salarySummarizing/downloadTotalSalary", data:params,responseType:"blob"}).then(data => data)


/**
 * 
 */
 export const downloadstaffRecordInfo = params => axios({method:'post',url:"/salarySummarizing/downloadstaffRecordInfo", data:params,responseType:"blob"}).then(data => data)


/**
 * 一键发薪列表
 */
export const easyPayList = params => axios.post("/salarySummarizing/listEasyPay",params).then(data => data)

/**
 * 发薪操作
 */
export const salaryPayment = (params) => axios.post("/salaryapply/salaryPayment",params).then(data => data)

/**
 * 查询租户信息
 */
 export const tenantIds = (params) => axios.post("/subAccount/getTenantRateInfo",params).then(data => data)

 /**
 * 一键发薪
 */
export const pay = (params) => axios.post("/salaryapply/pay",params).then(data => data)

 /**
 * 删除
 */
  export const deleteSalary = (params) => axios.post("/salarySummarizing/deleteSalaryById",params).then(data => data)
 
 /**
 * 查询
 */
  export const findSalary = (params) => axios.post("/salarySummarizing/findSalaryById",params).then(data => data)

 /**
 * 查询异常记录数据
 */
  export const listAuthFailedInfo = (params) => axios.post("/salarySummarizing/listAuthFailedInfo",params).then(data => data)

/**
 * 导出发薪记录
 */
 export const downloadSalaryLog = params => axios({method:'post',url:"/salarySummarizing/downloadSalaryLog", data:params,responseType:"blob"}).then(data => data)


/**
 * 导出子账号数据
 */
 export const downloadSubaccountLog = params => axios({method:'post',url:"/subAccount/downloadSubaccountLog", data:params,responseType:"blob"}).then(data => data)


 /**
 * 导出薪资异常数据
 */
  export const downloadSalaryErrorInfo = params => axios({method:'post',url:"/salarySummarizing/downloadSalaryErrorInfo", data:params,responseType:"blob"}).then(data => data)


/**
 * 基础设置-申报数据列表
 */
 export const PayrollDeclarationList = params => axios.post("/salarySummarizing/PayrollDeclarationList",params).then(data => data)


/**
 * 发薪记录-统计系统总数据
 */
 export const listPayrollInfo = params => axios.post("/payrollSummary/listPayrollInfo",params).then(data => data)


 /**
 * 发薪记录-统计系统总数据
 */
export const finSalarySummaryInfo = params => axios.post("/salarySummarizing/finSalarySummaryInfos_head",params).then(data => data)


 /**
 * 发薪记录-查询客户信息
 */
  export const finTenants = params => axios.post("/salarySummarizing/finTenants", params).then(data => data)


 /**
 * 转账设置-查询客户信息
 */
  export const finTenantAndz = params => axios.post("/subAccount/finTenants", params).then(data => data)

   /**
 * 转账设置-转账
 */
    export const deposit = params => axios.post("/subAccount/deposit", params).then(data => data)

   /**
 * 发薪-查询用户手机号
 */
    export const finUserInfo = params => axios.post("/salarySummarizing/finUserInfo", params).then(data => data)

 /**
 * 获取验证码
 */
  export const sendCode = params => axios.post("/common/sendCode", params).then(data => data)


 /**
 * 员工收入记录
 */
  export const payrollListData = params => axios.post("/salarySummarizing/payrollListData",params).then(data => data)

  /**
 * 查询账户余额
 */
export const subunitBalance = params => axios.post("/channels/subunitBalance",params).then(data => data)

/**
 * 查询账户历史交易
 */
 export const accountTransInfo = params => axios.post("/channels/accountTransInfo",params).then(data => data)


 /**
 * 查询电子对账单
 */
export const statementOfAccount = params => axios.post("/channels/statementOfAccount",params).then(data => data)

/**
* 打印电子对账单
*/
export const printStatement = params => axios.post("/channels/printStatement",params).then(data => data)

/**
* 发送短信验证码
*/
export const sendMobile = params => axios.post("/captcha/sendCode",params).then(data => data)


/**
* 校验短信验证码
*/
export const checkCode = params => axios.post("/captcha/checkCode",params).then(data => data)


/**
 * 查询发薪次数
 */
 export const listPayrollNumber = params => axios.post("/payrollSummary/listPayrollNumber",params).then(data => data)

    /**
 * 查询租户信息
 */
export const finTenantInfo = params => axios.post("/salarySummarizing/finTenantInfo", params).then(data => data)

    /**
 * 查询租户信息
 */
export const finTenantInfos = params => axios.post("/salarySummarizing/finTenantInfos", params).then(data => data)


 /**
 * 删除发票
 */
  export const deleteInvoice = (params) => axios.post("/invoice/deleteInvoiceById",params).then(data => data)

/**
 * 登录 - 免认证登录
*/
export const exemptVerifyLogin = (params) => axios.post("/sysUser/exemptVerifyLogin",params).then(data => data)

/**
* 首页柱状图
*/
export const findPageHistogram = params => axios.post("/payrollSummary/findPageHistogram",params).then(data => data)

/**
* 查询提现信息
*/
export const finWithdrawalTenant = params => axios.post("/channels/finWithdrawalTenant",params).then(data => data)

/**
* 提现
*/
export const withdrawal = params => axios.post("/channels/withdrawal",params).then(data => data)

/**
* 提现列表
*/
export const list = params => axios.post("/staff/list",params).then(data => data)

export const findSalaryState = params => axios.post("/salaryapply/findSalaryState",params).then(data => data)

/**
 * 导出一键发薪
 */
 export const downloadSalaryList = params => axios({method:'post',url:"/salarySummarizing/downloadSalaryList", data:params,responseType:"blob"}).then(data => data)

